@import url('https://unpkg.com/css.gg@2.0.0/icons/css/facebook.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/instagram.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/twitter.css');

footer {
    background-color: #36454f;
    padding: 30px 0;
    margin-top: 10px;
  }
  
  footer h3 {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
  }
  
  footer p {
    text-align: center;
    font-size: 16px;
    color: white;
  }
  
  footer ul li {
    margin-bottom: 10px;
    color: white;
  }
  
  footer ul li a {
    color: white;
    text-decoration: none;
  }
  
  footer ul li a:hover {
    color: #000;
  }
  
  .footer-bottom {
    background-color: #eee;
    padding: 10px 0;
  }
  
  .footer-bottom p {
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
  }

