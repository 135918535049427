/* Primera animacion con hover empezando con los textos y contenedor
  ----------------------------- */
*{margin: 0;padding: 0;box-sizing:border-box;font-family:sans-serif }

   .contenedor{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.contenedor figure{
   position:relative;
	height: 250px;
	cursor: pointer;
	width: 350px;
	overflow: hidden;
	border-radius: 6px;
	clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
	box-shadow: 0px 15px 25px rgba(0,0,0,0.50);
}
.contenedor figure img{
	width: 100%;
	height: 100%;
	clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
	transition: all 400ms ease-out;
	will-change: transform;
}
.contenedor figure .capa{
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,103,123,0.7);
	transition: all 400ms ease-out;
	opacity: 0;
	visibility: hidden;
   text-align: center;
}
.contenedor figure:hover > .capa {
	opacity: 1;
	visibility: visible;
}
.contenedor figure:hover > .capa h3{
	margin-top: 70px;
	margin-bottom: 15px;
}
.contenedor figure:hover > img{
	transform: scale(1.3);
}
.contenedor figure .capa h3{
	color: #fff;
	font-weight: 400;
	margin-bottom: 120px;
	transition: all 400ms ease-out;
	 margin-top: 30px;
}
.contenedor figure .capa p{
	color: #fff;
	font-size: 15px;
	line-height: 1.5;
	width: 100%;
	max-width: 220px;
	margin: auto;
}



/*
.column:last-child {
	padding-bottom: 60px;
}
.column::after {
	content: '';
	clear: both;
	display: block;
}
.column div {
	position: relative;
	float: left;
	width: 300px;
	height: 200px;
	margin: 0 0 0 25px;
	padding: 0;
}
.column div:first-child {
	margin-left: 0;
}
.column div span {
	position: absolute;
	bottom: -20px;
	left: 0;
	z-index: -1;
	display: block;
	width: 300px;
	margin: 0;
	padding: 0;
	color: #444;
	font-size: 18px;
	text-decoration: none;
	text-align: center;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	opacity: 0;
}
figure {
  border: 1px #cccccc solid;
  padding: 4px;
  margin: auto;
}
figure:hover+span {
	bottom: -36px;
	opacity: 1;
}
.hover11 figure img {
	opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

figcaption {
  background-color: black;
  color: white;
  font-style: italic;
  padding: 2px;
  text-align: center;
}*/