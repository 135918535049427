/* Estilos para Home**/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cairo');
body{
  background-image: url("./wp9220996.png"); 
  background-size: contain; 
  scroll-behavior: smooth;
}
.homeImg{ 
  
  max-width:60%;
  max-height:80%;
  margin: auto;
 
  border-radius: 10%;
}
.homeImg1 {
    background-color: rgb(255, 255, 255);
    background-position: 0%;
    max-width:90%;
    max-height:90%;
    margin: auto;
    cursor: pointer;
    align-items: center;
    border-radius:9% ;
    align-self: left;
    transition: .5;
}
.homeImg2 {
  background-color: rgb(255, 255, 255);
  max-width:50%;
  max-height:500px;
  margin: auto;
  align-items: center;
  border-radius:10% ;
}
.homeimage{
  align-items: center;
  margin: auto;
  height: 40vw;
  width: 30vw;
  border-radius:10% ;
}
.homeimage1{
  align-items: center;
  margin: auto;
  height: 40vw;
  width: 40vw;
  border-radius:10% ;
}
.homeimage2{
  align-items: center;
  margin: auto;
  height: 30vw;
  width: 50vw;
  border-radius:10% ;
}
/* Sweep To Right */

.item_1 {
  position: static;
  z-index: 1;
  overflow: hidden;
  margin: 10px;
  grid-column: span 2;
  height: auto;
  transition: all 400ms ease-out;
  display:inline-block;
  position:relative;
}

.text-center {
  text-align: center;
  color: white;
}
.row .text-center {

    background:  rgba(255, 230, 0, 0.05);

}
.OpacBack .text-center p{ 
  margin-left: 9vw;
}
.text-justify {
  text-align: justify;
  margin: 20px;  
  background:  rgba(255, 230, 0, 0.05);
  border-radius: 1%;
  }
  
   details, .text-justify{
    color: white;
  }
  .text-justify-2 {
    text-align: center;
    margin: 20px;  
    background:  rgba(255, 230, 0, 0.2);
    border-radius: 1%;
    }
/*///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
hover */
.item_1 figure {
  position: center;
  height: 100%; 
  width: 100%;
  cursor: pointer; 
  overflow: hidden;
  border-radius: 10%;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
}
.item_1  figure .capa {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  border-radius: 10%; 
  background: rgba(0,103,123,0.7);
  transition: all 400ms ease-out;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  overflow: hidden;
}
.item_1 figure:hover > .capa {
	opacity: 1;
	visibility: visible;
}
.item_1 figure:hover > .capa h3 {
  margin-top: 70px;
  margin-bottom: 15px;
}
.item_1 figure:hover > img {
  transform: scale(1.3);
}
.item_1 figure .capa h3 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 120px;
  transition: all 400ms ease-out;
  margin-top: 30px;
}
.item_1 figure .capa p {
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 1.5;
  width: 100%;
  max-width: 100%;
  margin: auto;
}
.item_1 figure a {
  text-align: center;
}
/* Sweep To Right ENDS */
.homeImg3 {
  background-color: rgb(0, 0, 0);
    max-width:50%;
    max-height:500px;
    margin: auto;
    align-items: center;
    border-radius:10% ;
}
col, h4,h3,h3,h2,h1{
  color: white;
  font-family: "EB+Garamond";
}

/* Estilos para Box**/
  .box-1{
    width: 100%;
    height: 100%;
    background-color: skyblue;
    border: 2px solid black;
 }
 .container{
    display: flex;
    height: 100vh;
    justify-content: flex-end;
    align-items: flex-end;
    margin: auto;
 }

 .invi{
  color:transparent;
 }
 /*Grid rules*/
 
.item {
  color: #333333; 
  font-size: 10pt; 
  font-family: ArialRegular;
}

hr {
  position: relative;
  border-top: 2px solid #FFBC3A;
  width: 647px;
  top: -35px;  border-top: 2px solid #FFBC3A;
  width: 647px;
}

.grid{
  display: inline-grid;
  row-gap: 10px;  
  grid-gap: 40% / 115px;
}

h1,h5,p{
  color: white;
  font-family: 'EB+Garamond';
  
}
label, small{
  color: white;
  display: inline-block;
}

/*seccion nosostros x /////////////////////////////////////////////////////////////////////////////////////*/
.lyrics h1{
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  justify-content: center;
}


/*Summary and details */

details summary::-webkit-details-marker { display: none;  }
summary::before {
  font-family: "Hiragino Mincho ProN", "Open Sans", sans-serif;
  content: "▶";
  position: absolute;
  top: 1rem;
  left: 0.8rem;
  transform: rotate(0);
  transform-origin: center;
  transition: 0.2s transform ease;
}
details[open] > summary:before {
  transform: rotate(90deg);
  transition: 0.45s transform ease;
}

/* # The Sliding Summary # */
details { overflow: hidden; }
details summary {
  position: relative;
  z-index: 10;
}
@keyframes details-show {
  from {
    margin-bottom: -80%;
    opacity: 0;
    transform: translateY(-100%);
  }
}
details > *:not(summary) {
  animation: details-show 500ms ease-in-out;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  color: transparent;
  overflow: hidden;
}
details[open] > *:not(summary) { color: inherit; }

details.style2 summary::before {
  content: "×";
  color: #FFF;
  font-size: 2rem;
  line-height: 1rem;
  transform: rotate(-45deg);
  top: 1.2rem;
  left: 0.5rem;
}
details[open].style2 > summary:before {
  transform: rotate(90deg);
  color: #F00 !important;
  transition: color ease 2s, transform ease 1s;
}
p { margin: 0; padding-bottom: 10px; }
p:last-child { padding: 0; }


details {
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  background:transparent;
 
}
details  li, p {
  color: white;
}
summary {
  border: 4px solid transparent;
  outline: none;
  padding: 1rem;
  display: block;
  background: #666;
  color: white;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}
details[open] summary,
summary:hover {
  color: #FFCA28;
  background: #444;
}
summary:hover strong,
details[open] summary strong,
summary:hover::before,
details[open] summary::before {
  color: #FFA128;
}

/* /*Summary and details ENDS */

/* float button*/
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  z-index: 10;
}

 /* para seccion de nosostros .....................................*/
 .wrapper {
  display: grid;
  gap: 1rem;
  grid-template-rows: 22rem;
  grid-template-columns: repeat(auto-fill,minmax(100%,25, rem), 1fr);
 }


 .h20 {
  color: white;
 }
 .pdireccion{
  text-align: center;
 }
 .OpacBack {    
  background:  rgba(255, 230, 0, 0.2);
  border-radius: 1%;
}
.OpacBack_1 {  
  background:  rgba(253, 253, 253, 0.05);
  border-radius: 1%;
}
.OpacBack_2 {  
  background:  rgba(1, 1, 1, 0.05);
  border-radius: 1%;
}
 /* para seccion de nosostros termina ..............................*/

/* conteiner map*/
.map-responsive{
  overflow:hidden;
  padding-bottom:40.25%;
  position:relative;
  height:0;
}

.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:95%;
  margin-left: 4%;
  position:absolute;
}
/* Another one*/
.Map{
 background-color: rgba(0, 0, 0, 0.005);
}
.hextitle{
  font-size: 50px;
  margin-bottom: -40px;
}
/* back to the top button................................................... */ 
.containertop{
  --offset: 100px;
  --fade: 80px;
  
  display: flex;
  align-items: flex-end;
  width: 60px;
  justify-self: end;
  justify-content: flex-end;

}

.top {
  padding: 10px;
  color: #fff;
  background:darkgoldenrod;
  cursor:pointer;
  border-radius: 100px;
  position:fixed;
  bottom:20px;
  right:20px;
  z-index:10;
  aspect-ratio: 1;
  width: 5%;
}
.top::before{
  content: "";
  inset: 30%;
  transform: translateY(20%)rotate(-45deg);
  border-top: 5px solid #FFF;
  border-right: 5px solid #FFF;
 position: absolute;
} 

.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style{
  background-color: #9d972a;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  background: #fff;
  color: #9d972a;
  border: 2px solid #551B54;
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}

/*Seccion de ventas o anuncios ///////////////////////////////////////////////////////////////////////////////////*/
.mdscreen {
 border-radius: 10%;
 margin: 10px;
 height: fit-content;
}

/*para la informacion de anuncios*/
.AnInfo{
  background-color: rgba(0, 0, 0, 0.1);
}
.AnInfo h1,p {
 text-align: left;
 margin:auto;

}

/* para el carrusel*/
.parallax-containerH .carousel-wrapper {
  margin: auto;
  margin-top:-50px ;
  margin-bottom:-100px ;
  max-width: 60vw;
}

/* seccion temporal */
.proximo  h1 {
	background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
	background-size: cover;
	color: transparent;
	-moz-background-clip: text;
	-webkit-background-clip: text;
	text-transform: uppercase;
	font-size: 120px;
	margin: 10px 0;
}
/* styling my button */
.proximo {
 margin-top: -30vw;
 margin-bottom: -20vw;
}

.proximo .title {
	font-family: "Cairo";
	text-align: center;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	letter-spacing: 1px;
	line-height:2
}

.proximo h1 {
	background-image: url(https://media1.giphy.com/media/t2aAdTgnU9Ie6jvG0W/giphy.gif?cid=ecf05e47llka6cq32rhp4ne11fnehn0ba8r6nwkpp379pnqm&ep=v1_gifs_search&rid=giphy.gif&ct=g);
	background-size: cover;
	color: transparent;
	-moz-background-clip: text;
	-webkit-background-clip: text;
	text-transform: uppercase;
	font-size: calc(1em + 3.5vw);
	margin: 1vw;
}
/* styling my button */

.white-mode {
	text-decoration: none;
	padding: 7px 10px;
	background-color: #122;
	border-radius: 3px;
	color: #FFF;
	transition: .35s ease-in-out;
	position: absolute;
	left: 15px;
	bottom: 15px;
	font-family: "Montserrat";
}

.white-mode:hover {
	background-color: #FFF;
	color: #122;
}

