input,
textarea {
  width: 30%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
form {
  width: 60%;
  padding: 16px;
  border-radius: 10px;
  margin: auto;
}

form label {
  width: 20%;  
  font-weight: bold;
  display: inline-block;
}

form input[type="text"],
form input[type="email"] {
  width: 30%;
  padding: 3px 10px;
  border: 1px solid #f6f6f6;
  border-radius: 3px;
  background-color: #f6f6f6;
  margin: 8px 0;
  display: inline-block;
}

form input:focus {
  background-color: rgb(205, 228, 238);
}
textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #f6f6f6;
  border-radius: 3px;
  background-color: #f6f6f6;
  margin: 8px 0;
  /*resize: vertical | horizontal | none | both*/
  resize: none;
  display: block;
}
textarea:focus {
  background-color: rgb(175, 219, 237);
}
.submit {
  border: 1px solid #2e518b; /*anchura, estilo y color borde*/
  padding: 10px; /*espacio alrededor texto*/
  background-color: #2e518b; /*color botón*/
  color: #ffffff; /*color texto*/
  text-decoration: none; /*decoración texto*/
  text-transform: uppercase; /*capitalización texto*/
  font-family: "Helvetica", sans-serif; /*tipografía texto*/
  border-radius: 50px; /*bordes redondos*/
  transition: all 0.3s cubic-bezier(0.67, 0.17, 0.4, 0.83);
}
form input[type="submit"]:hover {
  cursor: pointer;
  background-color: #4885e7;
  initial-value: "Enviado";
}
