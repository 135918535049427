@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import 'https://fonts.googleapis.com/css?family=Sintony';
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);

/* Parallax efffect part 1*/
:root {
    --color: rgb(63 63 63);
  }
.parallax-container {
    display: flex;
    min-height: 70vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /*  this is where  */
    background-attachment: fixed;
  
    background-position: center;
    background-size: cover;
    color: var(--color);
    row-gap: 4rem;
    opacity: 1;
    text-align: center;
  }
  .parallax-containerH {
    display: flex;
    min-height: 70vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    /*  this is where the magic happens:  */
    background-attachment: fixed;
  
    background-image: url("wp9220996.png");
    background-position: center;
    background-size: cover;
    color: var(--color);
    row-gap: 4rem;
  
    text-align: center;
  }
  
.buffer {
    width: 100%;
    min-height: 15vh;
    background-color: var(--color);
  }
  
  .parallax-container  .buffer {
    width: 100%;
    min-height: 60vh;
    background-color: var(--color);
  }
  
  .parallax-container  h1 {
    color: rgb(255, 187, 0);
    font-size: 2rem;
    font-weight: 40%;
    background:  rgba(255, 238, 0, 0.04);
    font-family: 'Press Start 2P', cursive;
  }
  
  .parallax-container  p {
    color: rgb(255, 187, 0);
    font-size: 2rem;
    max-width: 45ch;
    padding: 1em;
    line-height: 1.5;
    background:  rgba(255, 238, 0, 0.04);
    font-family: 'Press Start 2P', cursive;
  }
  
  .parallax-container a {
    display: inline-block;
    padding: 1em 3.5em;
    border-radius: 0.3em;
    background-color: var(--color);
    color: white;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .parallax-container h3 {
    margin-top: 1em;
    font-size: 1.6rem;
    font-weight: 700;
  }

  /* Parallax efffect part 2 ///////////////////////////////////////////////////////////////////////////////////////////////////////*/
  #main {width: 100%; padding:0;}
  .content-asset p {margin:0 auto;}
  .breadcrumb {display:none;}

/* Helpers */
/**************************/

.margin-top-10 {padding-top:10px;}
.margin-bot-10 {padding-bottom:10px;}

#parallax-world-of-ugg .h1blanco{
  font-family:'Oswald', sans-serif; font-size:20px; line-height:0;
         color:white;
}
#parallax-world-of-ugg li {
  font-family:'Source Sans Pro', sans-serif; font-size: 20px; 
}

  #parallax-world-of-ugg h1 {font-family:'Oswald', sans-serif; font-size:24px; font-weight:400;
     text-transform: uppercase; color:black; padding:0; margin:0;}
  #parallax-world-of-ugg h2 {font-family:'Oswald', sans-serif; font-size:6vw; letter-spacing:10px; text-align:center;
     color:white; font-weight:400; text-transform:uppercase; z-index:10; opacity:.9;  background-color: rgba(0, 0, 0, 0.07); overflow:visible;}
  #parallax-world-of-ugg h3 {font-family:'Oswald', sans-serif; font-size:14px; line-height:0;
     font-weight:400; letter-spacing:8px; text-transform: uppercase; color:black;}
  #parallax-world-of-ugg p {
    font-family:'Source Sans Pro', sans-serif; font-weight:400;
     font-size:14px; line-height:24px;}
  .first-character {font-weight:400; float: left; font-size: 84px; line-height: 64px; padding-top: 4px; padding-right: 8px; 
    padding-left: 3px; font-family: 'Source Sans Pro', sans-serif;}
 #parallax-world-of-ugg .blanco{font-family:'Oswald', sans-serif; font-size:20px; line-height:0;
    font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
 #parallax-world-of-ugg .blanco2{font-family:'Oswald', sans-serif; font-size:20px; line-height:0;
      font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
  #parallax-world-of-ugg .blanco3{font-family:'Oswald', sans-serif; font-size:20px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}

    @media screen and (min-width: 360px) and (max-width: 575px) {
      #parallax-world-of-ugg .blanco2{font-family:'Oswald', sans-serif; font-size:11px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #parallax-world-of-ugg .blanco3{font-family:'Oswald', sans-serif; font-size:18px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
        #parallax-world-of-ugg p {
          font-family:'Source Sans Pro', sans-serif; font-weight:400;
           font-size:11px; line-height:24px;}
    } 
    @media screen and (min-width: 575px) and (max-width: 669px) {
      #grid,#parallax-world-of-ugg .blanco {font-family:'Oswald', sans-serif; font-size:13px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #parallax-world-of-ugg .blanco2{font-family:'Oswald', sans-serif; font-size:8px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #grid,#parallax-world-of-ugg .blanco3 {font-family:'Oswald', sans-serif; font-size:9px; line-height:0;
          font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #parallax-world-of-ugg p {
          font-family:'Source Sans Pro', sans-serif; font-weight:400;
           font-size:11px; line-height:24px;}
           
    } 
     @media screen and (min-width: 668px) and (max-width: 846px) {
      #grid,#parallax-world-of-ugg .blanco {font-family:'Oswald', sans-serif; font-size:13px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #parallax-world-of-ugg .blanco2{font-family:'Oswald', sans-serif; font-size:13px; line-height:0;
        font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #grid,#parallax-world-of-ugg .blanco3 {font-family:'Oswald', sans-serif; font-size:13px; line-height:0;
          font-weight:400; letter-spacing:8px; text-transform: uppercase; color:white;}
      #parallax-world-of-ugg p {
          font-family:'Source Sans Pro', sans-serif; font-weight:400;
           font-size:11px; line-height:24px;}
           
           
    } 
    
    
 
  .sc {color: #3b8595;} 
  .ny {color: #3d3c3a;}
  .atw {color: #c48660;}
  
  
  /* Section - Title */
  /**************************/
  #parallax-world-of-ugg .title {background: white; padding: 60px; margin:0 auto; text-align:center;}
  #parallax-world-of-ugg .title h1 {font-size:35px; letter-spacing:8px;}
  
  /* Section - Block */
  /**************************/
  #parallax-world-of-ugg .block {background:rgba(255, 255, 255, 0.01); margin:auto; padding: 60px; width: 90%; margin:0 auto; margin-top:-20px; text-align:justify;}
  #parallax-world-of-ugg .block-gray {background: #f2f2f2;padding: 60px;}
  #parallax-world-of-ugg .section-overlay-mask {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: black; opacity: 0.70;}
  
  #parallax-world-of-ugg .block p{
    
    text-align: center;
    font-family: "EB+Garamond";
    font-size: 20px;
  }


  /* Section - Parallax */
  /**************************/
  #parallax-world-of-ugg .parallax-home {
    padding-top: 200px; 
    padding-bottom: 20vw; overflow: hidden; position: relative; width: 100%;
    background-image: url(/src/components/pages/img/building1.jpg);
    background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;}
  #parallax-world-of-ugg .parallax-domotica {
    padding-top: 200px;
    padding-bottom: 200px; overflow: hidden; position: relative; width: 100%;
    background-image: url(/src/components/pages/img/domotica.jpg);
    background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;}
  #parallax-world-of-ugg .parallax-econdo {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
      background-image: url(/src/components/pages/img/econdo.jpg); 
      background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat;
       background-position: center center; margin-top: 10px;
      }
  #parallax-world-of-ugg .parallax-constr {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
    background-image: url(/src/components/pages/img/elementoscons.jpg); 
    background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat;
     background-position: center center; margin-top: 10px;
     }
  #parallax-world-of-ugg .parallax-IOT {
    padding-top: 200px;
    padding-bottom: 200px; overflow: hidden; position: relative; width: 100%;
    background-image: url(/src/components/pages/img/IOT.jpg);
    background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
    background-repeat: no-repeat;   background-position: top center; margin-top: 10px;}
  #parallax-world-of-ugg .parallax-lumini {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
    background-image: url(/src/components/pages/img/Luminarias.jpg); 
    background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat;
     background-position: center center;margin-top: 10px;}
  #parallax-world-of-ugg .parallax-paneles {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
    background-image: url(/src/components/pages/img/panelsolar.jpg); 
    background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
     background-repeat: no-repeat; background-position: center center;margin-top: 10px;}
  #parallax-world-of-ugg .parallax-RV {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
      background-image: url(/src/components/pages/img/7.jpg);  
      background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
       background-repeat: no-repeat; background-position: center center;margin-top: 10px;}
  #parallax-world-of-ugg .parallax-seguridad {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
        background-image: url(/src/components/pages/img/seguridad.jpg); 
        background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
         background-repeat: no-repeat; background-position: center center;margin-top: 10px;}
  #parallax-world-of-ugg .parallax-semaforos {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
          background-image: url(/src/components/pages/img/semaforo.jpg ); 
          background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
           background-repeat: no-repeat; background-position: center center;margin-top: 10px;}
  #parallax-world-of-ugg .parallax-tinaco {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; 
      background-image: url(/src/components/pages/img/agua.jpg ); 
      background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
      background-repeat: no-repeat; background-position: center center;margin-top: 10px;}
          
          
  
  #parallax-world-of-ugg .parallax-x {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url(https://images.unsplash.com/photo-1415018255745-0ec3f7aee47b?dpr=1&auto=format&fit=crop&w=1500&h=938&q=80&cs=tinysrgb&crop=); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: top center;}
  #parallax-world-of-ugg .parallax-econdo h2 {
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.09);
  }

  /* Extras */
/**************************/
#parallax-world-of-ugg .line-break {border-bottom:1px solid black; width: 150px; margin:0 auto;}

/* Media Queries */
/**************************/


@media screen and (max-width: 959px) and (min-width: 768px) {
  #parallax-world-of-ugg .block {padding: 40px; width:620px;}
}
@media screen and (max-width: 767px) {
  #parallax-world-of-ugg .block {padding: 30px; width:420px;}
  #parallax-world-of-ugg .block {padding: 30px;}
  #parallax-world-of-ugg .parallax-home, #parallax-world-of-ugg .parallax-two, #parallax-world-of-ugg .parallax-three {padding-top:100px; padding-bottom:100px;}
}
@media screen and (max-width: 479px) {
  #parallax-world-of-ugg .block {padding: 30px 15px; width:290px;}
 
}