*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .bodyhex {
  background-color: aliceblue;
}*/

.hex {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.h1  {
  text-align: center;
  padding: 2.5rem 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #e7dac2;
  font-size: 2.4rem;
  font-family: sans-serif;
}
.bodyhex a {
  transition: 1;
}

.gallery {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 133px);
  grid-auto-rows: 200px;
  justify-content: center;
  gap: 1rem;
}
.gallery a {
  color: white;
}

.gallery_item {
  position: static;
  z-index: 1;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  grid-column: span 2;
  height: 283px;
  transition: all 400ms ease-out;
  will-change: transform;
}
.gallery figure {
  position: center;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  height: 100%;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
}

.gallery_item figure .capa {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,103,123,0.7);
  transition: all 400ms ease-out;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  text-align: center;
}
.gallery_item figure:hover > .capa {
	opacity: 1;
	visibility: visible;
}
.gallery_item figure:hover > .capa h3 {
  margin-top: 70px;
  margin-bottom: 15px;
}
.gallery_item figure:hover > img {
  transform: scale(1.3);
}
.gallery_item figure .capa h3 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 120px;
  transition: all 400ms ease-out;
  margin-top: 30px;
}
.gallery_item figure .capa p {
  color: #fff;
  font-size: 15px;
  line-height: 1.5;
  width: 100%;
  max-width: 220px;
  margin: auto;
}

/*para tamanos de la galeria*/

.gallery_item:first-of-type {
  grid-column: 2 / span 2;
}

@media screen and (min-width: 270px) and (max-width: 504px) {
  .gallery_item:first-of-type {
    grid-column: 1 / span 2;
  }
  .gallery {
    grid-auto-rows: 283px;
  }

}

@media screen and (min-width: 505px) and (max-width: 685px) {
  .gallery_item:nth-of-type(odd) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 686px) and (max-width: 860px) {
  .gallery_item:nth-of-type(2n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 861px) and (max-width: 882px) {
  .gallery_item:nth-of-type(3n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 882px) and (max-width: 1032px) {
  .gallery_item:nth-of-type(4n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 1033px) and (max-width: 1213px) {
  .gallery_item:nth-of-type(4n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 1214px) and (max-width: 1232px) {
  .gallery_item:nth-of-type(4n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 1233px) and (max-width: 1391px) {
  .gallery_item:nth-of-type(6n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 1391px) and (max-width: 1407px) {
  .gallery_item:nth-of-type(6n + 1) {
    grid-column: 2 / span 2;
  }
}

@media screen and (min-width: 1408px) {
  .gallery_item:nth-of-type(7n + 1) {
    grid-column: 2 / span 2;
  }
}

/* CSS del Boton*/
.btn {
  border: 1px solid #2e518b; /*anchura, estilo y color borde*/
  padding: 10px; /*espacio alrededor texto*/
  background-color: #2e518b; /*color botón*/
  color: #ffffff; /*color texto*/
  text-decoration: none; /*decoración texto*/
  text-transform: uppercase; /*capitalización texto*/
  font-family: "Helvetica", sans-serif; /*tipografía texto*/
  border-radius: 50px; /*bordes redondos*/
  transition: all 0.3s cubic-bezier(0.67, 0.17, 0.4, 0.83);
}

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}
.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.colla {
  color: #ffffff;
}
