/*importamos fuente de texto monsterrat*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import 'https://fonts.googleapis.com/css?family=Montserrat|Open+Sans';

.nav-item::after {
    content: '';
    display: block;
    width: 0px;
    height: 2px;
    background: #fec400;
    transition: 0.4s
}

.nav-item:hover::after {
    width: 80%
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fec400
}

.nav-link {
    padding: 25px 5px;
    transition: 0.2s
}

.logo{
    width: 120px;
    height: 120px;
    margin-top: -10px;
    margin-bottom: -30px;
    margin-right: -110px;
    background-color: rgba(255, 255, 255, 0.001);
}

footer .logo {
    position: center;
    margin-right: -10px;
}